/* eslint-disable */
import React from 'react'
import { Route } from 'react-router-dom'

const RouteWithLayout = ({ layout: ResLayout, component, ...rest }) =>
  ResLayout ? (
    <ResLayout>
      <Route
        {...rest}
        children={props => React.createElement(component, props)}
      />
    </ResLayout>
  ) : (
    <Route
      {...rest}
      children={props => React.createElement(component, props)}
    />
  )

export default RouteWithLayout
