/* eslint-disable */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ImgUrl, getFloatWindowData } from '../../data/ajax'
import closeBtn from '../../images/closeBtn.png'
import './index.less'
import { connect } from 'react-redux'
import store from '../../redux/store'
import * as Constants from '../../redux/constants'

// 全局飘窗
class FloatWindow extends Component {
  constructor(props) {
    super(props);
    this.state={
      activeStyle:'', // 飘窗动态样式类
    }
  }
  

  componentDidMount() {   
    const { winData } =this.props
    if(winData.fdIsDisplay){
      this.setPosition(winData)
    }
   }

  //  componentWillReceiveProps(nextProps) {
  //   console.log(nextProps.showWinStatus);
  // }

   isShowWin = ()=>{
    const { showWinStatus } = this.props
    let showWin = false
    if(showWinStatus === false){
      return showWin
    }else{
      const {winData, path }= this.props
      const position = winData.fdFloatPosition
      if(position === 'home' && path === '/'){
        showWin = true
      }else if(position === 'all'){
        showWin = true
      }
      return showWin
    }
  }
 

  // 设置飘窗位置
  setPosition = (winData)=>{
    const imgLength = winData && winData.busSecurityFloatImgList && winData.busSecurityFloatImgList.length
    if(imgLength > 0 && winData.fdFloatPath != "fixed") {
      if (winData.fdFloatPath == "updown") {
        var ad1 = new AdMove("img1", winData.fdPageLocation);
        if (winData.fdPageLocation === 'right') {
          this.setState({ activeStyle: 'right01' }) // 飘窗页面位置:右侧+上下浮动
        }
      } else if (winData.fdFloatPath == "topLeftBottomRight") {
        var ad1 = new AdMove("img1", '');
      }
      ad1.Run();
    }else{
      if(winData.fdPageLocation === 'right'){
        this.setState({ activeStyle: 'right01' }) // 当飘动轨迹=fixed, 飘窗位置=右
      }
    }
  }


  openUrl = (url)=>{
    console.log('打开url',url)
    if(url){
      window.open(url, "_blank");
    }
  }


  closeWin =()=>{
    store.dispatch({
      type: Constants.SET_SHOW_STATUS,
      payload: {
        showWinStatus: false
      }
    })
    this.setState({ activeStyle: 'hideDom' }) // 隐藏飘窗可见性
  }

  render() {
    const { activeStyle} = this.state
    const { winData } = this.props
    let imgList = (winData && winData.busSecurityFloatImgList) || []
    // imgList = imgList.concat(imgList)
    return (
      <div className={`floatWindow-box ${activeStyle}`} id="img1" style={{ display: this.isShowWin() ?'block':'none' }}>
        <div class="rangeList">
            { imgList.map(x=>{
              return (
                <img src={ImgUrl+x.fdImgPath} onClick={()=>this.openUrl(x.fdUrl)}/>
              )
            }) }
        </div>
        <div className="close" onClick={()=>this.closeWin()}><img src={closeBtn} />全部关闭</div>
      </div>
    )
  }
}

FloatWindow.defaultProps = {
  history: {}
}

FloatWindow.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number
  })
}
function mapStateToProps(state) {
  return {
    showWinStatus: state.showFloatWinReducer.showWinStatus
  }
}
export default withRouter(connect(mapStateToProps)(FloatWindow))
