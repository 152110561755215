import React, { Component } from 'react'
// import { HashRouter as Router, /* Route, */ Switch } from 'react-router-dom'
import { BrowserRouter as Router, /* Route, */ Switch } from 'react-router-dom'
import ResponsiveLayout from './layout/ResponsiveLayout'
import RouteWithLayout from './utils/RouterWithLayout'
import asyncComponent from './utils/AsyncComponent'
import noRequireAuth from './components/hoc/no_require_auth'

// 首页
const Home = asyncComponent(() => import('./pages/Home'))
const Mlbh = asyncComponent(() => import('./pages/Mlbh'))
const Cyfw = asyncComponent(() => import('./pages/Cyfw'))
const Ktyl = asyncComponent(() => import('./pages/Ktyl'))
const Dtyw = asyncComponent(() => import('./pages/Dtyw'))
const Detail = asyncComponent(() => import('./pages/Dtyw/detail'))

// 会议服务
const MeetingService = asyncComponent(() => import('./pages/MeetingService'))
// 客房服务
const RoomService = asyncComponent(() => import('./pages/RoomService'))

class App extends Component {
  componentDidMount() {}

  render() {
    return (
      <Router>
        <Switch>
          {/* 首页 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/"
            exact
            component={noRequireAuth(Home)}
          />
          {/* 魅力北会 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/mlbh"
            exact
            component={noRequireAuth(Mlbh)}
          />
          {/* 餐饮服务 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/cyfw"
            exact
            component={noRequireAuth(Cyfw)}
          />
          {/* 康体娱乐 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/ktyl"
            exact
            component={noRequireAuth(Ktyl)}
          />
          {/* 动态要闻 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/dtyw"
            exact
            component={noRequireAuth(Dtyw)}
          />
          {/* 动态要闻详情 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/dtywDetail"
            exact
            component={noRequireAuth(Detail)}
          />
        </Switch>

        <Switch>
          {/* 会议服务 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/meetingService"
            exact
            component={noRequireAuth(MeetingService)}
          />
        </Switch>
        <Switch>
          {/* 客房服务 */}
          <RouteWithLayout
            layout={ResponsiveLayout}
            path="/roomService"
            exact
            component={noRequireAuth(RoomService)}
          />
        </Switch>
      </Router>
    )
  }
}
export default App
