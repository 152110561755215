import * as constants from '../constants'

const initialState = {
  showWinStatus: true
}

export default function userData(state = initialState, { type, payload }) {
  switch (type) {
    case constants.SET_SHOW_STATUS:
      return { ...state, showWinStatus: payload.showWinStatus }
    default:
      return state
  }
}
