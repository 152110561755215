/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { ImgUrl, getFloatWindowData } from '../../data/ajax'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FloatWindow from '../../components/FloatWindow'

class ResponsiveLayout extends React.PureComponent {
  state = {
    winData:{},
    // isAllClose:localStorage.getItem('isAllClose') || false
  }

  componentDidMount(){
    this.getData()
  }

  getData = ()=>{
    getFloatWindowData().then(res => {
      if(res.data.success){
        var obj = res.data.result[0] || {}
        console.log('获取飘窗-res', obj)
        this.setState({ winData: obj })
      }
    })
  }

  render() {
    const { children } = this.props
    const { winData } = this.state
    const path = window.location.pathname
    return (
      <div className="jg-layout">
        <Layout style={{ backgroundColor: '#fff'}}>
          <Header />
          <div style={{ minHeight: '500px' }}>{children}</div>
          { winData && winData.fdIsDisplay &&  <FloatWindow winData={winData} path={path} />}
          <Footer />
        </Layout>
      </div>
    )
  }
}

ResponsiveLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default ResponsiveLayout
