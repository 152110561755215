import * as constants from '../constants'

const initialState = {
  refreshData: false,
}

export default function userData(state = initialState, { type, payload }) {
  switch (type) {
    case constants.GET_AFFAIRS_DATA:
      return { ...state, refreshData: payload.refreshData }
    default:
      return state
  }
}
