import React, { Component } from 'react'
import './index.less'

import erweima from '../../images/erweima.png'

class Footer extends Component {
  state = {}

  render() {
    return (
      <div id="footerBox">
        <div className="container">
          <div className="column1">
            <div className="title"> 北京会议中心欢迎您</div>
            <div className="address">地址：中国北京市朝阳区來广营西路88号</div>
            <div className="">北京会议中心版权所有京ICP备12018201号-1</div>
          </div>
          <div className="column2">
            <div className="title">热线服务电话</div>
            <div className="number">010-84901668</div>
            <div className="">010-55571688/99</div>
          </div>
          <div className="column3">
            <div className="title">联系我们</div>
            <div className="number">传真：8610-84925218</div>
          </div>
          <div className="column4">
            <div className="title">关注我们</div>
            <img className="erweima" alt="二维码" src={erweima} />
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
