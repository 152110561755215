import axios from 'axios'
import qs from 'qs'

axios.defaults.withCredentials = true
// 添加统一token
axios.interceptors.request.use(config => {
  if (config.method == 'post' || config.method == 'POST') {
    config.data = {
      ...config.data,
      _t: Date.parse(new Date()) / 1000
    }
  } else if (config.method == 'get' || config.method == 'GET') {
    config.params = {
      _t: Date.parse(new Date()) / 1000,
      ...config.params
    }
  }
  // config.headers['X-Access-Token'] =
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJvcmdjb2RlIjoiQTAzIiwiZXhwIjoxNzE1MzA1MzQyLCJ1c2VyaWQiOiJlOWNhMjNkNjhkODg0ZDRlYmIxOWQwNzg4OTcyN2RhZSIsInVzZXJuYW1lIjoiYWRtaW4ifQ.nqtkATeeS9aV3AG5z1T19CPwR_zDAVp4iW1wJM-7lgc'
  return config
})

const BaseUrl = `${BASE_URL}`
export const ImgUrl = `${BASE_URL}/sys/common/static/`

// 获取用户菜单列表
export function getUserMenuList() {
  return axios.get(`${BASE_URL}/admin/sys/menu/list`, {
    dataType: 'application/json'
  })
}

// 获取字典
export function getDictItems(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/sys/dict/getDictItems/${params}`
  })
}

// 首页
export function getHomeData(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/online/cgform/api/getData/55b40e39fd5e47169eeace422cee830a`,
    params
  })
}

// 会议服务
export function getMeetingServiceListData(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/online/cgform/api/getData/68a6b4eac8114ca4bdb905970094c2c5`,
    // url: `${BASE_URL}/online/cgform/api/getData/68a6b4eac8114ca4bdb905970094c2c5${
    //   id ? `/${id}` : ''
    // }`,
    params
  })
}

// 客房服务
export function getRoomListData(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/online/cgform/api/getData/0795c40168fd43c1ac4eb9b51d11ad3b`,
    params
  })
}
// // 用户注册接口
// export function sendEmail(params) {
//   return axios({
//     method: 'POST',
//     url: `${BASE_URL}/admin/api/user/sendEmail`,
//     data: {
//       ...params
//     }
//   })
// }

// // 我的消息
// export function myMessage(params) {
//   return axios({
//     method: 'GET',
//     url: `${BASE_URL}/personal_center/myMessageData`,
//     params
//   })
// }

// 轮播图
export function getCarousoulList(params) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/online/cgform/api/getData/102f133b1e0543579e6eb27c0555e68a`,
    params
  })
}

// 魅力北会
export function getMlbhData(params) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/online/cgform/api/getData/576cf972148e4fa8acbb099e6fdb309b`,
    params
  })
}

// 餐饮服务
export function getCyfwData(params) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/online/cgform/api/getData/36b8ecfb6f7740f99ffac6c3eba895b8`,
    params
  })
}

// 康体娱乐
export function getKytylData(params) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/online/cgform/api/getData/9738a2b04fa8458abf8ab18e9b26f828`,
    params
  })
}

// 动态要闻 相关
// 01 获取栏目
export function getDtywData01(params) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/index/busSecurityIndex/queryChildren2`,
    params
  })
}

// 02 获取指定栏目的文章列表
export function getDtywList(params, source) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/index/busSecurityIndex/getColumnDate`,
    params,
    cancelToken: source ? source.token : {}
  })
}

// 获取文章详情
export function getDtywDetail(id) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/index/busSecurityIndex/queryById/${id}`
  })
}

// 获取全局飘窗
export function getFloatWindowData(params) {
  return axios({
    method: 'GET',
    url: `${BaseUrl}/index/busSecurityIndex/queryFloatBySiteId`,
    params
  })
}
