/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:25
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-08-25 23:56:03
 */
import React, { Component } from 'react'
// import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export default function(ComposedComponent) {
  class NotAuthentication extends Component {
    PropTypes = {
      router: PropTypes.object,
      authenticated: PropTypes.bool
    }

    componentWillMount() {
      const { authenticated, history } = this.props
      if (authenticated) {
        history.push('/')
      }
    }

    componentWillUpdate(nextProps) {
      const { history } = this.props
      if (nextProps.authenticated) {
        history.push('/')
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }
  NotAuthentication.defaultProps = {
    history: {}
  }

  NotAuthentication.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      length: PropTypes.number
    })
  }
  // function mapStateToProps(state) {
  //   return { authenticated: state.userReducer.isAuthenticated }
  // }
  return NotAuthentication
  // connect(mapStateToProps)(NotAuthentication)
}
