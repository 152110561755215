import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import TopBar from './TopBar' // 头部
// import Menu from './Menu' // 菜单
// import Title from './Title' // 菜单
// import SearchBar from './SearchBar' // 搜索框
// import Affairs from './affairs' // 办事
import './index.less'

class Header extends Component {
  state = {
    isFixed: false
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll, true)
  }

  handleScroll = () => {
    let scrollHeight = document.body.scrollHeight 
    // scrollHeight > 1100 加这个条件是为了避免动态要闻详情页内容少高度不够 滚不到底
    if(scrollHeight > 1100 && window.scrollY >= 100){
      this.setState({ isFixed:true })
    }else{
      this.setState({ isFixed:false })
    }
  }

  render() {
    const {
      history: {
        location: { pathname }
      }
    } = this.props
    const isHome = pathname === '/'
    const { isFixed } = this.state
    const classNames = isFixed ? "index-body fixed-body" : "index-body"
    return (
      <div className={classNames} id="header">
        <div className="homeHeaderBox">
          <TopBar />
          {/* {!isHome && <Menu />}
          {isHome && <Title />}
          <SearchBar />
          <Affairs /> */}
        </div>
      </div>
    )
  }
}

Header.defaultProps = {
  history: {}
}

Header.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number
  })
}
export default withRouter(Header)
