import * as constants from '../constants'

const initialState = {
  currentDetail: '{}'
}

export default function userData(state = initialState, { type, payload }) {
  switch (type) {
    case constants.SAVE_CURRENT_DETAIL:
      return { ...state, currentDetail: payload.currentDetail }
    default:
      return state
  }
}
