/* eslint-disable */
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Select, Popover } from 'antd'
import { connect } from 'react-redux'
import store from '../../redux/store'
import * as Constants from '../../redux/constants'

import {  } from '../../data/ajax'

import topLogo from '../../images/top-logo.png'

const { Option } = Select
class TopBar extends Component {
  state = {
    menuList: [
      { index: '1', name: '首页', url: '/' },
      { index: '2', name: '魅力北会', url: '/mlbh' },
      { index: '3', name: '会议服务', url: '/meetingService' },
      { index: '4', name: '客房服务', url: '/roomService' },
      { index: '5', name: '餐饮服务', url: '/cyfw' },
      { index: '6', name: '康体娱乐', url: '/ktyl' },
      { index: '7', name: '动态要闻', url: '/dtyw' },
    ]
  }

  componentDidMount() {
    // this.getTabs()
    // this.getCurrentUser()
  }

  // 获取当前登录用户
  getCurrentUser = () => {
  }

  // 获取个人办事，单位办事主题
  getTabs = () => {
   
  }


  // 是否是动态要闻详情
  isDtywDetail = (pathName)=>{
    let str = ''
    if(pathName === '/'){
      str = '首页'
    }
    if(pathName === '/dtywDetail'){
      str = '动态要闻'
    }
    return str
  }


  render() {
    const {  menuList } = this.state
    const {
      history: {
        location: { pathname }
      }
    } = this.props
    const isHome = pathname === '/'
    return (
      <div className="topBox">
        <img className="topLogo" src={topLogo} alt="" />
        <div className="box">
          <ul className="nav">
            {menuList.map(itm =>
              <li>
                  <Link
                    to={itm.url}
                    className={pathname === itm.url || this.isDtywDetail(pathname) === itm.name? 'cur' : ''}
                  >
                    {itm.name}
                  </Link>
                </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}

TopBar.defaultProps = {
  history: {}
}

TopBar.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number
  })
}
function mapStateToProps(state) {
  return {
  }
}

export default withRouter(connect(mapStateToProps)(TopBar))
