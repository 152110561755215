import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import App from './App'
import store from './redux/store'
// import * as Constants from './redux/constants'
import './less/site.theme.less'
import './less/site.less'

import * as serviceWorker from './registerServiceWorker'

moment.locale('zh-cn')

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
)

// registerServiceWorker()
serviceWorker.unregister()
