/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:52:59
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-08-25 23:55:51
 */
import { combineReducers } from 'redux'
import tadBerReducer from './tadBerReducer' // 二级路由共享

import currentUserReducer from './currentUserReducer' // 当前用户信息
import refreshReducer from './refreshReducer' // 搜索页当前搜索类型

import showFloatWinReducer from './showFloatWinReducer' // 显示浮窗
import saveNewsDetailReducer from './saveNewsDetailReducer' // 显示浮窗

const allReducers = {
  tadBerReducer,
  currentUserReducer,
  refreshReducer,
  showFloatWinReducer,
  saveNewsDetailReducer
}
const rootReducer = combineReducers(allReducers)
export default rootReducer
