import * as constants from '../constants'

const initialState = {
  tadBerType: ''
}

export default function wenterData(state = initialState, { type, payload }) {
  switch (type) {
    case constants.WENTER_DATA:
      return { tadBerType: payload, ...payload }
    default:
      return state
  }
}
