import * as constants from '../constants'

const initialState = {
  currentUser: {},
  topUnitId: -1,
  homeApp:{},
  subjectData: {} //事项主题数据
}

export default function userData(state = initialState, { type, payload }) {
  switch (type) {
    case constants.SAVE_CURRENT_DATA:
      return { ...state, currentUser: payload.currentUser }
    case constants.CHANGE_TOP_UNIT:
      return { ...state, topUnitId: payload.topUnitId }
    case constants.SAVE_HOME_APP:
      return { ...state, homeApp: payload.homeApp }
    case constants.SAVE_SUBJECT_DATA:
      return { ...state, subjectData: payload.subjectData }
    default:
      return state
  }
}
