/*
 * @Author: wangyafei
 * @Description: 所有 Redux 状态常量
 * @Date: 2018-06-06 13:52:41
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-08-25 23:56:17
 */

// 用户登录，退出常量
export const USER_LOGGING_IN = 'USER_LOGGING_IN'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
// 天气信息刷新
export const WENTER_DATA = 'WENTER_DATA'
// 二级taber 刷新
export const TABER_TYPE = 'TABER_TYPE'


// 用户信息
export const SAVE_CURRENT_DATA = 'SAVE_CURRENT_DATA'
// 事项主题信息
export const SAVE_SUBJECT_DATA = 'SAVE_SUBJECT_DATA'


// 搜索的类型
export const SEARCH_TYPE = 'SEARCH_TYPE'
// 搜索触发
export const SEARCH_FLAG = 'SEARCH_FLAG'
// 搜索值
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
// top切换单位，触发首页刷新应用导航信息
export const CHANGE_TOP_UNIT = 'CHANGE_TOP_UNIT'
// 首页下方app数据
export const SAVE_HOME_APP = 'SAVE_HOME_APP'
// 触发获取头部header中事务数据
export const GET_AFFAIRS_DATA = 'GET_AFFAIRS_DATA'

// 显示飘窗
export const SET_SHOW_STATUS = 'SET_SHOW_STATUS'
// 显示飘窗
export const SAVE_CURRENT_DETAIL = 'SAVE_CURRENT_DETAIL'
